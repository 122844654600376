// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import "stylesheets/bootstrap5/application_smart_phone"
import "stylesheets/front_smart_phone"
import "stylesheets/jquery-ui"

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

require("src/common")
require("src/smart_phone/application")
require("src/smart_phone/jquery.rwdImageMaps.min")
require("packs/smart_phone/jquery.flexslider-min")
require("src/smart_phone/jquery.alerts")
require("src/smart_phone/owl.carousel.min")
require("src/smart_phone/styleswitch")
require("packs/sna_efriends")
require("packs/smart_phone/portal")
require("packs/smart_phone/jquery.flexslider-min")
require('jquery-ui/ui/effect')
require('jquery-ui/ui/effects/effect-highlight')
require('jquery-ui/ui/widgets/dialog')
require('jquery-ui/ui/widgets/autocomplete');
require('quagga/dist/quagga')
require("src/smart_phone/camera")
require("src/smart_phone/manage_search_log_smart_phone")

const images = require.context("../images", true)

import Quagga from 'quagga/dist/quagga.js'
globalThis.Quagga = Quagga;
