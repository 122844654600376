import Quagga from 'quagga/dist/quagga';

$(function(){
  $(document).on('click', '#future_order_camera_btn' , function() {
    jAlert('カメラが起動します。商品バーコードを読み取ってください。<br>※端末によっては動作しないことがあります。', '', function () { cameraBuild(); });
  });

  function cameraBuild() {
    $("#camera_dialog").css('display', 'block');
    Quagga.init({
      inputStream: {
        type: "LiveStream",
        target: document.querySelector('#camera_container'),
        constraints: {
          decodeBarCodeRate: 3,
          successTimeout: 500,
          codeRepetition: false,
          tryVertical: true,
          frameRate: 15,
          width: 900,
          height: 1100,
          facingMode: "environment"
        }
      },
      decoder: {
        readers: [ "ean_reader" ]
      }
    },
    function(err) {
      if (err) {
        console.log(err);
        return;
      }
      Quagga.start();
    });

    Quagga.onProcessed(function(result){
      var ctx = Quagga.canvas.ctx.overlay;
      var canvas = Quagga.canvas.dom.overlay;

      ctx.clearRect(0, 0, parseInt(canvas.width), parseInt(canvas.height));

      if (result) {
        if (result.box) {
          console.log(JSON.stringify(result.box));
          Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, ctx, {color: 'blue', lineWidth: 2});
        }
      }
    });

    Quagga.onDetected(function(result){
      $('#future_order_search_text').val(result.codeResult.code);
      Quagga.stop();
      $("#camera_dialog").dialog("close");
      $("#camera_dialog").css('display', 'none')
      $('#future_products_search_form').submit();
    });

    var containerSize = $(window).width() - 10;
    if ($(window).width() > $(window).height()) {
      containerSize = $(window).height() - 10;
    }
    $("#camera_dialog").dialog({
      autoOpen: false,
      title: "",
      width: containerSize,
      height: containerSize,
      closeText: "",
      modal : true,
      close: function(event, ui) {
        $("#camera_dialog").css('display', 'none')
        Quagga.stop();
      }
    });
    $('button.ui-dialog-titlebar-close').html('<span class="icon-ibatu" style="font-size: 30px;"></span>')
    $("#camera_dialog").dialog("open");
  }
});
