// -*- coding: utf-8 -*-
// Place your application-specific JavaScript functions and classes here
// This file is automatically included by javascript_include_tag :defaults
window.ajaxEnable = function(flg){
  $.mobile.ajaxEnabled = flg; // ajax on
  $.mobile.linkBindingEnabled = flg; // on automatically bind the clicks on anchor tags.
}

//3桁区切りカンマ付与
window.addComma = function(str){
  var num = new String(str).replace(/,/g, '');
  while(num != (num = num.replace(/^(-?\d+)(\d{3})/, '$1,$2')));
  return num;
}
window.open_dialog = function(message){
  var unit = $('input[name="search_condition[select_type]"]:checked').val();
  var select = $("#search_condition_project_id").val();
  if (unit == 1){
      select = $("#search_condition_delivery_name").val();
  }
  if (select == null){
      jAlert(message, "");
      return false;
  }

  var data_string="";
  data_string = data_string + "&" + "search_condition=true"
  //data_string = data_string + "&" +"dialog_flg=true"
  data_string = data_string + "&" +"p_unit=" + unit;
  data_string = data_string + "&" +"p_select=" + select;

  jAlertIframe(encodeURI($('#open_dialog').attr('data-href') + data_string,'100%'));

  return false;

}
//ダイアログを閉じる
window.dialog_close = function(){
  unlockWindow();
  $('body').removeClass("show_dialog");
  $(".page_hide").css({display: ''}).removeClass("page_hide");
  $('.ui-dialog').dialog('close');
  ajaxEnable(false);
}
window.lockWindow = function(){
  $("#lock").css({height: $(document).height()}).show();
}
window.unlockWindow = function(){
  $("#lock").hide();
}
//単位変更時の表示切り替え
window.p_change_unit = function(unit){
  if(unit ==1){
      $("#p_unit_info_1").css("display", "block");
      $("#p_unit_info_2").css("display", "none");
  }else{
      $("#p_unit_info_1").css("display", "none");
      $("#p_unit_info_2").css("display", "block");
  }
  $('#p_plan').empty().append( $('#plan_'+ unit +' option').clone());
  $('#p_plan').val($('#plan_'+ unit).val());
  $('select').selectmenu('refresh',true);
  p_change_select();
}
window.p_change_select = function(){
  var closing_name = $('#p_plan').val()+'_closing'
  var delivery_name = $('#p_plan').val()+'_delivery'
  var closing_disp = $('input[name=' + closing_name + ']').val();
  var delivery_disp = $('input[name=' + delivery_name + ']').val();
  $('#closing').text(closing_disp);
  $('#delivery').text(delivery_disp);
}

window.sbiComment_sp = function(serial, id, option, charset, target_id) {
  sbiKuchikomi_sp("cm", serial, id, option, charset, target_id);
}

window.sbiKuchikomi_sp = function(type, serial, id, option, charset, target_id) {
  var __kuchikomi_host = "r.snva.jp";

  if(location.href.match("\\?")){
      add_query = RegExp.rightContext;
  }else{
      add_query = ""
  }

  add_query = add_query ? "&" + add_query : "";

  var url = location.protocol + "//" + __kuchikomi_host;
  option = option ? "&" + option : "" ;

  if(!navigator.userAgent.match(/Mozilla\/4\.0 \(compatible; MSIE 5\.\d*; (Windows|MSN|AOL)/)) {
    charset = "UTF-8";
  }

  switch(type){
  case "cm":
    target_id = target_id || "sbi_comment";
    url += "/cm/list/";
    break;
  case "tb":
    url += "/tb/list/";
    target_id = target_id || "sbi_trackback";
    break;
  }

  url += serial + "/" + id  + "?target_id=" + target_id + "&charset=" + charset + option + add_query;

  $.ajax({
    url: url,
    cache: false,
    dataType: 'script'
  })
  .done(function() {
    $("#sbi_comment9 select,#sbi_comment9 :submit").attr("data-role","none");
    $("#sbi_comment9 form").attr("autocomplete","off");
    $("#sbi_comment9 textarea").css("height","125px");
    $("#sbi_comment9").trigger("create");
  });
};

var login_confirm_dlg_login_btn_hidden_flg = false;
var login_confirm_dlg_entry_btn_hidden_flg = false;

window.login_confirm = function(){
  jLoginAlert('ログインしてから購入をお願いします。', '', function(r) {
    if(r==1){
      // ログイン画面へ
      login_confirm_dlg_login_btn_hidden_flg = true;
      $("#login_confirm_dlg_login_btn_hidden").click();
    } else if(r==2) {
      // 会員登録画面へ
      login_confirm_dlg_entry_btn_hidden_flg = true;
      $("#login_confirm_dlg_entry_btn_hidden").click();
      return false;
    } else {
      return false;
    }
  });
  return false;
}

window.popup_product_image_sp = function(image_tag, prod_id){
  jPopup_img('', image_tag, '','300px', prod_id, function(r) {
    return false;
  });
  return false;
}

window.popup_product_movie_sp = function(product_movie_url){
  jPopup_movie_sp('', product_movie_url, '','300px', function(r) {
    return false;
  });
  return false;
}

$(function(){
  //////////////////////////////////////////
  // ログイン画面へ
  $("#login_confirm_dlg_login_btn_hidden").click(function(){
    // きちんとボタン処理が行われたか判断する
    if(login_confirm_dlg_login_btn_hidden_flg){
      login_confirm_dlg_login_btn_hidden_flg = false;
      return true;
    } else {
      return false;
    }
  });
  //////////////////////////////////////////
  // 会員登録画面へ
  $("#login_confirm_dlg_entry_btn_hidden").click(function(){
    // きちんとボタンが行われたか判断する
    if(login_confirm_dlg_entry_btn_hidden_flg){
      login_confirm_dlg_entry_btn_hidden_flg = false;
      return true;
    } else {
      return false;
    }
  });
});

//function checkEnvironmentDependence(str){
//    checkEnvironmentDependenceForAskul(str);
//}

window.allergyAreaToggle = function(obj) {
  $("#allergy_area").toggleClass("hidden");
  $(obj).find(".btn_allergy").toggleClass("btn_allergy-btn_allergy_off btn_allergy-btn_allergy_on");
}

$(function() {
  $("[id^='allergy_checkbox'").on('click', function() {
    allergyCheckToggle(this);
  });
});

window.allergyCheckToggle = function(obj){
  var checkbox = $(obj).prev(":checkbox");
  var key = $(obj).attr("data-key");
  var css_class = "allergen_icons-" + key;
  $(obj).toggleClass(css_class + " " + css_class + "_sentaku");
};

// テキストボックスに入力された全角数字を半角数字に変換
window.zen2hanNum = function(obj) {
  str = obj.value;
  hankaku = str.replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });

  obj.value = hankaku;
}

$('.decrement_quantity_btn').on('click', function(){
  var id = $(this).data('id');
  var autoload = $(this).data('autoload');
  decrementQuantityById(id, autoload);
});

// 時間指定画面遷移
// 引数
//   to_url:    リンク先URL
//   from_date: リンク開始日時
//   to_date:   リンク終了日時
window.toLinkOnTime = function(to_url, from_date, to_date) {
  var dtFrom = new Date(from_date);
  var dtTo   = new Date(to_date);
  var dtNow  = new Date();
  if (dtFrom <= dtNow && dtNow <= dtTo) {
    window.location.href = to_url;
  }
  return false;
}

// ヘッダメニュー呼び出し
$(function(){
  $('.menu-toggle').on('click', function() {
    toggleHeaderMenu(this, '#' + $(this)('id'));
  });
});

// ヘッダ部アイコンクリック
window.toggleHeaderMenu = function(elem, toggleSelecter) {
  $('.header-icon').each(function (index, element) {
    if (elem != element) $(element).removeClass('icon-on');
  });
  // メニューアイコン切り替え
  if (toggleSelecter == '#drawer_nemu') {
    var itag_off = $(elem).children("i[class$=icon--448]");
    var itag_on = $(elem).children("i[class$=icon-i01on]");
    if (itag_off.length){
      $(itag_off).removeClass("icon--448").addClass("icon-i01on");
    };
    if (itag_on.length){
      $(itag_on).removeClass("icon-i01on").addClass("icon--448");
    };
  }else{
    var itag_on = $($("li.header-menu").children("i[class$=icon-i01on]"));
    if (itag_on.length){
      $(itag_on).removeClass("icon-i01on").addClass("icon--448");
    };
  };

  if ($(elem).attr('id') == 'header_status')
    $('#header_status_icon').toggleClass('icon-on');
  else if ($(elem).attr('id') == 'header_search')
    $('#header_search_icon').toggleClass('icon-on');
  else
    $(elem).toggleClass('icon-on');

  var items = ["#header_search", "#header_status", "#drawer_nemu","#header_point"];
  items.filter(function (val) {
    return val != toggleSelecter;
  }).map(function (val) {
    return $(val).hide();
  });
  $(toggleSelecter).toggle();
  if($(toggleSelecter).is(':visible')) {
    $(".login-username-area").hide();
    $(".header-project-contents").hide();
    $("#body_content").hide();
    $("#future_search_navbar").hide();
  } else {
    $(".login-username-area").show();
    $(".header-project-contents").show();
    $("#body_content").show();
    $("#future_search_navbar").show();
  }
  // WebView表示時、ネイティブ側の処理を発火させる
  if (toggleSelecter == "#header_status" && window.webkit != undefined) {
    window.webkit.messageHandlers.removeBadges.postMessage("お知らせバッジ削除");
  }

  // お知らせ表示時
  if (toggleSelecter == "#header_status") {
    closeDeliveryPush();
    openDeliveryStatus();
  }

  return false;
}

// ヘッダ部合計金額リフレッシュ
window.refreshHeaderTotalPrice = function() {
  if(!($('#header_total_price').length) && !($('#header_total_quantity').length)){
    return;
  }
  var limited = $('#limited').text();
  var post_url = $('#header_post_url').text();
  $.ajax({
    type: 'POST',
    url: '/' + post_url + '/get_total_price' + limited,
    async: true,
    timeout: 10000,
    cache: false
  })
  .done(function(data, status) {
      $('#header_total_price').text(data.total_price);
      $('#header_total_quantity').text(data.total_quantity);
  })
  .fail(function (XHR, status, errorThrown) {
      //エラー時は処理しない
  });
};

window.locationChange = function(elem) {
  var url = $(elem).find('a').attr('href');
window.location.href = url;
}

$(function(){
  $('.location_change').on('click', function() {
    locationChange(this);
  });
});


$(function() {
  var jqxhrFirst = null;

  /* 配送状況API */
  const ajaxFirstApi = () => {
    return new Promise((resolve, reject) => {
      if (jqxhrFirst) {
        // 通信を中断する
        // ただしfail(), always()は実行される
        jqxhrFirst.abort();
      }

      let api_url = $('#delivery_status_api_url').text();
      let dsn_delivery_date = $('#dsn_delivery_date').val();
      let dsn_coop_code = $('#dsn_coop_code').val();
      let dsn_kumi_code = $('#dsn_kumi_code').val();
      jqxhrFirst = $.ajax({
        type: 'POST',
        url: '/delivery_info/get_deliveryapi',
        data : {delivery_date: dsn_delivery_date,
                coop_code: dsn_coop_code,
                kumi_code: dsn_kumi_code},
        async: true,
        timeout: 10000,
        cache: false,
        dateType: 'json',
        headers: {'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content')}
      })
      .done(function(data, status) {
        resolve(data);
        return false;
      })
      .fail(function (XHR, status, errorThrown) {
        reject('配送状況APIエラー');
        return false;
      });
    });
  }

  /* 画面情報取得API */
  const ajaxSecondApi = (data) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: '/delivery_status_notifications/get_deliveryinfo',
        data: data,
        async: true,
        timeout: 10000,
        cache: false
      })
      .done(function(data, status) {
        resolve(data);
        return false;
      })
      .fail(function (XHR, status, errorThrown) {
        reject('画面情報取得APIエラー');
        return false;
      });
    });
  };

  var timeoutId;
  $("#header_status_icon, #header_status_close").on('click', function() {
    changeMode(Mode.loading);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function(){
      if ($('#header_status').is(':visible')) {
        ajaxFirstApi()
        .then(ajaxSecondApi)
        .then((result) => {

          if (result.is_delivery == "true") {
            const elem = document.getElementById("delivery_info");
            elem.scrollIntoView();
          }

          if (result.message == "") {
            let deliveryNum = Number(result.delivery_num);
            let deliveryEndnum = Number(result.delivery_endnum);

            for (var i = 1; i <= 5; i++) {
              let deliNo = (deliveryNum < 5) ? (i - 1) : (deliveryNum - (5 - i));
              if (deliNo == 0) {
                $(".dsn-step-label" + i).text('配送センター');
                $(".dsn-step" + i).addClass('is-preparation');
              } else {
                $(".dsn-step-label" + i).text(deliNo + '番目', deliNo);
              }

              $(".dsn-step" + i).removeClass('is-active');
              if (deliNo == deliveryEndnum) {
                $(".dsn-step" + i).addClass('is-active');
              }

              if (deliNo == deliveryNum) {
                $(".dsn-step-sublabel" + i).text('あなたのお届け');
                $(".dsn-step" + i).addClass('target');
              }
            }

            if (result.display_image) {
              $("#upd_time").text(result.update_time);
              $("#dsn_img_memo").text(result.image_memo);
              $("#dsn_status_message").text(result.status_message);
              $("#dsn_notification").text(result.notification);
              changeMode(Mode.status);
            } else {
              if (result.notification == "") {
                $("#dsn_message").text(result.status_message);
                changeMode(Mode.message);
              } else {
                // イメージ非表示 且つ 緊急時のお知らせがある場合のみ
                $("#dsn_status_message").text(result.status_message);
                $("#dsn_notification").text(result.notification);
                changeMode(Mode.messageWithNotif);
              }
            }

          } else {
            $("#dsn_message").text(result.message);
            changeMode(Mode.message);
          }
        })
        .catch((err) => {
          $("#dsn_message").text('現在メインテナンス中の為、\r\nアクセスできません');
          changeMode(Mode.message);
        });
      } else {
        if (jqxhrFirst) {
          // 通信を中断する
          jqxhrFirst.abort();
        }
      }
    }, 500);

  });

  var Mode = {
    loading : 1,
    status : 2,
    message : 3,
    messageWithNotif : 4
  };

  const changeMode = (mode) => {
    switch(mode) {
      case Mode.loading:
          showSection("#dsn_loading_section");
        break;
      case Mode.status:
          $(".dsn-status-header").removeClass('message-only');
          $(".dsn-status-title").css('visibility','visible');
          $(".dsn-status-body").show();
          showSection("#dsn_status_section");
        break;
      case Mode.message:
          $(".dsn-status-header").removeClass('message-only');
          $(".dsn-status-title").css('visibility','visible');
          $(".dsn-status-body").hide();
          showSection("#dsn_message_section");
        break;
      case Mode.messageWithNotif:
          $(".dsn-status-header").addClass('message-only');
          $(".dsn-status-title").css('visibility','hidden');
          $(".dsn-status-body").hide();
          showSection("#dsn_status_section");
        break;
      }
  };

  const showSection = (section) => {
    var items = ["#dsn_loading_section", "#dsn_status_section", "#dsn_message_section"];
    items.map(function (val) {
      if (val == section) {
        $(val).show();
      } else {
        $(val).hide();
      }
    });
  };
});

window.closeDeliveryPush = function() {
  $.ajax({
    url: '/customer_information_group/set_session',
    type: 'POST',
    data: {},
    data_type: 'json'
  }).done(function () {
    $('#info_container').remove();
    $('#info_container_aichi').remove();
    return false;
  });
};
