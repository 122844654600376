// -*- coding: utf-8 -*-
$(function() {
  var $slider = $('.best10-slider');
  $slider.owlCarousel({
    loop: false,
    rewind : false,
    nav: false,
    dots: false,
    margin: 10,
    items: 1,
    stagePadding: 20,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    freeDrag: false,
    lazyLoad: true,
    center: true
  })

  var $pslider = $('.prod-slider');
  $pslider.owlCarousel({
    rewind : false,
    nav: false,
    dots: false,
    margin: 5,
    items: 3,
    stagePadding: 10,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    freeDrag: false,
    lazyLoad: true
  })

  // owlCarousel画像遅延読み込み
  // スクロールイベントをトリガにlazyLoadで読まれなかった画像を取得
  // イベントは1度実行したらoff()で破棄
  $('.owl-stage-outer').on("scroll", function(e){
    $(this).off();
    $elements = $(this).find('.owl-lazy');
    if (!$elements) {
      return;
    }

    $elements.each($.proxy(function(index, element) {
      var $element = $(element), image,
        url = (window.devicePixelRatio > 1 && $element.attr('data-src-retina')) || $element.attr('data-src') || $element.attr('data-srcset');

      if ($element.is('img')) {
        $element.one('load.owl.lazy', $.proxy(function() {
          $element.css('opacity', 1);
        }, this)).attr('src', url);
          } else if ($element.is('source')) {
            $element.one('load.owl.lazy', $.proxy(function() {
            }, this)).attr('srcset', url);
      } else {
        image = new Image();
        image.onload = $.proxy(function() {
          $element.css({
            'background-image': 'url("' + url + '")',
            'opacity': '1'
          });
        }, this);
        image.src = url;
      }
    }, this));
  });
});

document.addEventListener('DOMContentLoaded', function() {
  const orderMenuToggleBtn = document.getElementById('order-menu-toggle-btn');
  const menu = document.getElementById('hide-order-icons');
  const showFullOrderMenu = localStorage.getItem('showFullOrderMenu');
  // 閉じる→もっと見る
  function closeMoreMenu() {
    if (orderMenuToggleBtn) {
      orderMenuToggleBtn.classList.remove('btn-with-top');
      orderMenuToggleBtn.classList.add('btn-with-under');
      orderMenuToggleBtn.querySelector('h6').textContent = 'もっと見る';
      localStorage.setItem('showFullOrderMenu', 'false');
      menu.classList.remove('show');
    }
  }
  // もっとみる→閉じる
  function showMoreMenu() {
    if (orderMenuToggleBtn) {
      orderMenuToggleBtn.classList.remove('btn-with-under');
      orderMenuToggleBtn.classList.add('btn-with-top');
      orderMenuToggleBtn.querySelector('h6').textContent = '閉じる';
      localStorage.setItem('showFullOrderMenu', 'true');
      menu.classList.add('show');
    }
  }
  // 初期表示設定
  if (showFullOrderMenu === 'true' || showFullOrderMenu === null) {
    showMoreMenu();
  } else {
    closeMoreMenu();
  }
  // クリックイベント
  if (orderMenuToggleBtn) {
    orderMenuToggleBtn.addEventListener('click', function() {
      if (orderMenuToggleBtn.classList.contains('collapsed')) {
        closeMoreMenu();
      } else {
        showMoreMenu();
      }
      orderMenuToggleBtn.classList.toggle('collapsed');
    });
  }
});
// $(function(e) {
//   $('#order_icons').rwdImageMaps();
// });

// var $win = $(window);
// $win.on('load resize', function() {
//   var windowWidth = window.innerWidth;
//   if (windowWidth > 639) {
//     // TABの処理
//     $("#order_icons").attr("scroll_right_border", 3926);
//     $("#order_icons").addClass("icons-wide");
//   } else {
//     // SPの処理
//     $("#order_icons").attr("scroll_right_border", 2126);  /* cssの.order-icons  width の値と同期させること */
//     $("#order_icons").removeClass("icons-wide");
//   }
// });