$(function () {
  // 検索履歴取得
  var showSearchWordsCount = 5;
  var searchWordLogs = localStorage.getItem('searchWordLogs');
  if (!searchWordLogs) {
    searchWordLogs = []
  } else {
    searchWordLogs = JSON.parse(searchWordLogs)
  }

  // 検索履歴追加
  searchKeywordNow = $('#search_keyword_now').text();
  if (searchKeywordNow && searchKeywordNow != '') {
    if (searchWordLogs.includes(searchKeywordNow)) {
      searchWordLogs = searchWordLogs.filter(function(word) {
        return word != searchKeywordNow;
      });
    }
    searchWordLogs.unshift(searchKeywordNow);
    if (searchWordLogs.length > showSearchWordsCount) {
      searchWordLogs = searchWordLogs.slice(0, showSearchWordsCount);
    }
  }
  localStorage.setItem('searchWordLogs', JSON.stringify(searchWordLogs));

  $(".auto_comp_search_text_box").click(function() {
    $(this).autocomplete({
      source: searchWordLogs,
      minLength: 0,
      collapsible: true
    });

    // 検索予測取得
    $(this).autocomplete("search", "");
  });
});